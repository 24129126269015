import CopyBlock from '../copy-block/copy-block';
import type { GetHotelHomePageQuery } from '../../generated/types';
import type { TWithCustomTheme } from '../../helpers/themes/customTheme';

export type PartnershipInfoProps = TWithCustomTheme<{
  partnership: NonNullable<GetHotelHomePageQuery['hotel']>['partnerships'][number];
}>;

const PartnershipInfo = ({ partnership, wrapperClass }: PartnershipInfoProps) => {
  return (
    <CopyBlock
      headline={partnership.headline || ''}
      paragraphText={partnership.desc || ''}
      termsAndConditions={partnership?.relatedPolicy?.terms?.description}
      primaryButton={
        partnership.links[0]
          ? {
              label: partnership.links[0].label || '',
              url: partnership.links[0].url || '',
              isNewWindow: partnership.links[0].isNewWindow || false,
              variant: 'outline',
            }
          : null
      }
      secondaryButton={
        partnership.links[1]
          ? {
              label: partnership.links[1].label || '',
              url: partnership.links[1].url || '',
              isNewWindow: partnership.links[1].isNewWindow || false,
              variant: 'outline',
            }
          : null
      }
      image={partnership.images?.[0]}
      logoUrl={partnership.logoUrl || ''}
      theme="light"
      wrapperClass={wrapperClass}
    />
  );
};

export default PartnershipInfo;
